import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`2020 caught us by surprise but it ended up with only demise`}</p>
    <p>{`It started in january when australia was on fire, it escalated quickly to a whole wildfire!`}<br parentName="p"></br>{`
`}{`In that same month, it stormed the news, we saw a contagious virus that had us so confused!`}</p>
    <p>{`1 month later, we were all quite scared, we went into lockdown and had to share our best experiences and not any despair.`}</p>
    <p>{`That month passed and this one came, and all inside we all felt shame.`}</p>
    <p>{`When April arrived there was a glimmer of hope, even though our skies were filled with smoke.`}</p>
    <p>{`That hope turned into fear as we started to appear in malls, shops and the theatr!`}<br parentName="p"></br>{`
`}{`But we wore our masks for safety and precaution, and we stayed inside with very little options.`}</p>
    <p>{`When the next month arrived we were all in our rooms except for the people protesting with a bruise.`}<br parentName="p"></br>{`
`}{`July was the same except for the bees they exploded as much as this disease!`}<br parentName="p"></br>{`
`}{`They raided some houses and stormed some too, we all hated that, even the animals in the zoo.`}</p>
    <p>{`In august the bees had gone, but now Lebanon had a bomb, Beirut had exploded and the world felt empathy. It was a horrible experience that left our hearts empty.`}</p>
    <p>{`While Lebanon was recovering, RBG had died, it took us by surprise and left us with tears in our eyes.`}</p>
    <p>{`Fires were raging, the candidates were debating, 2020 was ending and our hopes were fading.`}</p>
    <p>{`The elections have arrived and our hopes have survived, and we won this election with quite a collection.`}</p>
    <p>{`2020 sure did suck, but maybe next year we’ll have some luck.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      